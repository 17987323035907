import React from "react"
import { Link } from "gatsby"
import { Helmet } from 'react-helmet';

import SEO from "../components/seo/seo"
import { StaticImage } from 'gatsby-plugin-image'

import codeIcon from './../images/icons/bx-code-alt.svg';
import wrentchIcon from './../images/icons/bx-wrench.svg';
import categoryIcon from './../images/icons/bx-category-alt.svg';

const CareersPage = () => (
  <>
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@type": "JobPosting",
            "title": "Reactjs Developer",
            "description": "<p>Working for us requires a passion for entrepreneurship, innovation, problem-solving. <br/><br/>We are always looking for driven, interesting and capable individuals to join our team. In addition to our agency business, we have several in-house projects and companies.<br/><br/>If you think you fit the bill, then we'd like to chat.</p>",
            "identifier": {
              "@type": "PropertyValue",
              "name": "Heisentech",
              "value": "r1"
            },
            "datePosted": "2021-07-08",
            "validThrough": "2017-08-31T00:00",
            "applicantLocationRequirements": {
              "@type": "Country",
              "name": "IN"
            },
            "jobLocationType": "TELECOMMUTE",
            "employmentType": "FULL_TIME",
            "hiringOrganization": {
              "@type": "Organization",
              "name": "Heisentech",
              "sameAs": "http://heisentech.com",
              "logo": "http://localhost:8000/static/images/heisentech-new-logo.svg"
            },
            "baseSalary": {
              "@type": "MonetaryAmount",
              "currency": "INR",
              "value": {
                "@type": "QuantitativeValue",
                "value": 360000,
                "unitText": "YEAR"
              }
            }
          }
        `}
      </script>
    </Helmet>
    <SEO title="Careers | heisentech" />
    <section className="section hero">
      <div className="container big">
        <div className="col text">
          <h3 className="title" title="Careers">Careers</h3>
          <p className="desc">
            Working for us requires a passion for entrepreneurship, innovation, problem-solving. <br/><br/>
            We are always looking for driven, interesting and capable individuals to join our team.
            In addition to our agency business, we have several in-house projects and companies.<br/><br/>
            If you think you fit the bill, then we'd like to chat.
          </p>
          <Link to="#opportunities" className="btn-link">Check out the opportunities below.</Link>
        </div>
        <div className="col img-wrapper">
          <StaticImage
            src="./../images/careers-hero.png"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            placeholder="none"
            alt="Careers"
          />
        </div>
      </div>
    </section>
    <section className="section" id="opportunities">
      <div className="container big opportunities">
        <div className="opportunity">
          <div className="name-wrapper">
            <h3 className="name">Reactjs <br/>Developer</h3>
            <img className="icon" src={codeIcon} alt="Code icon"/>
          </div>
          <h6 className="label">Qualifications</h6>
          <ul>
            <li>2+ years of React development experience.</li>
            <li> Good understanding of DOM APIs and functional programming</li>
            <li>Excellent analytical, logical and communication skills</li>
            <li>Should be able to work independently.</li>
          </ul>
          <h6 className="label">Your Objectives</h6>
          <ul>
            <li>Translate application requirements and use cases into functional applications.</li>
            <li>Design, build, and maintain efficient, reusable, and reliable code</li>
            <li>Research and integration of third party services as per client requirements.</li>
            <li>Partner with designers, project managers and clients to deliver high-quality experiences from concept stage through launch.</li>
          </ul>
          <div className="btn-wrapper">
            <a
              href="mailto:careers@heisentech.com?subject=Job application for role of Reactjs developer @heisentech"
              className="btn-link">
              Apply now
            </a>
          </div>
        </div>
        <div className="opportunity">
          <div className="name-wrapper">
            <h3 className="name">Quality <br/>Analyst</h3>
            <img className="icon" src={wrentchIcon} alt="QA icon"/>
          </div>
          <h6 className="label">Qualifications</h6>
          <ul>
            <li>Comprehensive knowledge of Software testing life cycle, Bug life cycle, Automation and Manual Testing</li>
            <li>Excellent analytical, logical and communication skills</li>
            <li>Should be able to coordinate with developers towards resolving issues</li>
            <li>Should be able to work independently.</li>
          </ul>
          <h6 className="label">Your Objectives</h6>
          <ul>
            <li>Responsible for the design of test strategy, test cases and test data, Develop and document test scenarios and cases based on functional profiles.</li>
            <li>Manage test requirement definition, test design, test script and test data development, test automation, test environment configuration management and test execution.</li>
          </ul>
          <div className="btn-wrapper">
            <a
              href="mailto:careers@heisentech.com?subject=Job application for role of Quality analyst @heisentech"
              className="btn-link">
              Apply now
            </a>
          </div>
        </div>
        <div className="opportunity">
          <div className="name-wrapper">
            <h3 className="name">UX <br/>Designer</h3>
            <img className="icon" src={categoryIcon} alt="UX icon"/>
          </div>
          <h6 className="label">Qualifications</h6>
          <ul>
            <li>2+ years in the field of interaction design and user experience.</li>
            <li>Creative thinker with excellent storytelling/presentation skills and an ability to articulate and discuss your design decisions with clients and partners</li>
            <li>Excellent analytical, logical and communication skills</li>
          </ul>
          <h6 className="label">Your Objectives</h6>
          <ul>
            <li>Partner with other designers, program managers, Marketing, Digital Strategy and business units to deliver high-quality experiences from concept stage through launch</li>
            <li>May have to present creative strategies and concepts to clients, both internally and externally</li>
            <li>Interpret customer requirements to deliver engaging, innovative, and immersive user solutions and experiences</li>
          </ul>
          <div className="btn-wrapper">
            <a
              href="mailto:careers@heisentech.com?subject=Job application for role of UX Designer @heisentech"
              className="btn-link">
              Apply now
            </a>
          </div>
        </div>
      </div>
    </section>
  </>
)

export default CareersPage
